import { gsap } from "gsap";

export class appHeroSlider {
  constructor() {
    this.elm = document.querySelector(".js-app-hero-slider");

    if (!this.elm) return;

    this.dom = {
      elm: this.elm,
      slider: this.elm.querySelector(".js-slider"),
      slides: [...this.elm.querySelectorAll(".js-slide")],
      captions: [...this.elm.querySelectorAll(" .js-caption")],
      indicators: [...this.elm.querySelectorAll(".js-indicator")],
    };

    this.data = {
      current: 0,
      last: 0,
      total: this.dom.slides.length - 1,
      slideTimer: 3,
      indicators: true,
      awaitingSlide: -1,
    };

    this.state = {
      animating: false,
      currentTime: 0,
      currentTick: 0,
      isAwaiting: false,
    };

    this.dom.captions.map((caption) => {
      caption.item = caption.querySelector(".js-caption__item");
    });

    this.dom.indicators.map((indicator) => {
      indicator.progress = indicator.querySelector(
        ".js-indicator svg .progress"
      );
    });

    this.dom.slides.map((slide) => {
      slide.item = slide.querySelector(".js-slide__item");
      slide.timer = slide.dataset.slideTimer
        ? parseInt(slide.dataset.slideTimer)
        : this.data.slideTimer;
    });

    this.currentSlide = this.dom.slides[this.data.current];
    this.currentCaption = this.dom.captions[this.data.current];

    this.bindMethods();
    this.init();
  }

  bindMethods() {
    ["next"].forEach((fn) => (this[fn] = this[fn].bind(this)));
  }

  changeSlide() {
    this.state.animating = true;
    this.state.currentTick = 0;
    this.lastSlide = this.dom.slides[this.data.last];
    this.currentSlide = this.dom.slides[this.data.current];
    this.lastCaption = this.dom.captions[this.data.last];
    this.currentCaption = this.dom.captions[this.data.current];

    this.currentSlide.classList.add("-active");
    this.currentCaption.classList.add("-active");

    if (this.currentSlide.querySelector("video")) {
      this.currentSlide.querySelector("video").play();
    }

    if (this.data.indicators) {
      this.dom.indicators[this.data.last].classList.remove("-active");
      this.dom.indicators[this.data.current].classList.add("-active");
      this.resetLastIndicatorProgress();
    }

    this.slideTweenIn = gsap
      .timeline({
        paused: true,
        onComplete: () => {
          this.state.animating = false;
          this.lastSlide.classList.remove("-active");
          this.lastCaption.classList.remove("-active");

          if (this.state.isAwaiting) {
            this.go(this.data.awaitingSlide);
          }
          this.state.isAwaiting = false;
          this.data.awaitingSlide = -1;
        },
      })

      .set([this.currentSlide, this.currentCaption], {
        autoAlpha: 1,
        zIndex: 3,
      })
      .set([this.lastSlide, this.lastCaption], {
        zIndex: 2,
        autoAlpha: 1,
      })
      .fromTo(
        this.currentSlide.item.querySelector(".slide__img.--index1 .img"),
        {
          xPercent: -100,
        },
        {
          xPercent: 0,
          ease: "expo.inOut",
          duration: 2.5,
        },
        0.9
      )
      .fromTo(
        this.currentSlide.item.querySelector(".slide__img.--index1 .img img"),
        {
          scale: 1.25,
          xPercent: 100,
        },
        {
          scale: 1,
          xPercent: 0,
          ease: "expo.inOut",
          duration: 2.5,
        },
        0.9
      )
      .fromTo(
        this.currentSlide.item.querySelector(".slide__img.--index2 .img"),
        {
          xPercent: -100,
        },
        {
          xPercent: 0,
          ease: "expo.inOut",
          duration: 3,
        },
        1
      )
      .fromTo(
        this.currentSlide.item.querySelector(".slide__img.--index2 .img img"),
        {
          scale: 1.15,
          xPercent: 100,
        },
        {
          scale: 1,
          xPercent: 0,
          ease: "expo.inOut",
          duration: 3,
        },
        1
      )
      // .fromTo(
      //   this.lastCaption.item,
      //   {
      //     opacity: 1,
      //     // xPercent: 0,
      //   },
      //   {
      //     opacity: 0,
      //     // xPercent: 10,
      //     ease: "power3.inOut",
      //     duration: 1.25,
      //   },
      //   0
      // )
      // .fromTo(
      //   this.currentCaption.item,
      //   {
      //     opacity: 0,
      //     // xPercent: -10,
      //   },
      //   {
      //     opacity: 1,
      //     // xPercent: 0,
      //     ease: "power4.inOut",
      //     duration: 1.9,
      //   },
      //   1.7
      // )
      .fromTo(
        this.lastCaption.item.querySelector(".bg"),
        {
          scaleX: 1,
          transformOrigin: "right",
        },
        {
          scaleX: 0,
          ease: "power4.inOut",
          duration: 1.7,
        },
        0.5
      )
      .staggerFromTo(
        [
          this.lastCaption.querySelectorAll("h2 .line span"),
          this.lastCaption.querySelector("h4"),
        ],
        1.1,
        {
          opacity: 1,
          x: 0,
        },
        {
          opacity: 0,
          x: 120,
          ease: "power4.in",
        },
        0.1,
        0
      )
      .fromTo(
        this.currentCaption.item.querySelector(".bg"),
        {
          scaleX: 0,
          transformOrigin: "left",
        },
        {
          scaleX: 1,
          ease: "power4.out",
          duration: 1.4,
        },
        2.5
      )
      .staggerFromTo(
        [
          this.currentCaption.querySelectorAll("h2 .line span"),
          this.currentCaption.querySelector("h4"),
        ],
        1.5,
        {
          opacity: 0,
          x: -50,
        },
        {
          opacity: 1,
          x: 0,
          ease: "power3.out",
        },
        0.1,
        3.2
      )
      .set([this.lastSlide, this.lastCaption], {
        //zIndex: 2,
        autoAlpha: 0,
      })
      .play();
  }

  next() {
    if (this.state.animating) return;

    this.data.last = this.data.current;
    this.data.current =
      this.data.current === this.data.total ? 0 : this.data.current + 1;

    this.changeSlide();
  }

  go(slide) {
    if (this.state.animating) {
      this.state.isAwaiting = true;
      this.data.awaitingSlide = slide;
      return;
    }
    if (this.data.current == slide) return;
    this.data.last = this.data.current;
    this.data.current = slide;
    this.changeSlide();
  }

  setTimer() {
    gsap.ticker.add(() => {
      this.state.currentTick++;
      this.state.currentTime =
        Math.round((this.state.currentTick / 60) * 100) / 100; // 60 is from rAF fps

      this.setCurrentIndicatorProgress(
        this.state.currentTime / this.currentSlide.timer
      );

      if (this.state.currentTime > this.currentSlide.timer) {
        this.next();
      }
    });
  }

  setCurrentIndicatorProgress(progress) {
    gsap.set(
      this.dom.indicators[this.data.current].querySelector(".progress"),
      {
        strokeDasharray: `${parseInt(129 * progress)} 129`,
        strokeDashoffset: `1`,
      }
    );
  }

  resetLastIndicatorProgress() {
    gsap.to(this.dom.indicators[this.data.last].querySelector(".progress"), {
      strokeDasharray: `130 130`,
      strokeDashoffset: `-129`,
      duration: 1.25,
      ease: "power3.out",
    });
  }

  setInitAnimation() {
    this.initTween = gsap
      .timeline()
      .set([this.currentSlide], {
        autoAlpha: 1,
        zIndex: 3,
      })
      .set([this.dom.slider.querySelector(".js-slider__caption")], {
        perspective: 900,
      })
      .fromTo(
        [this.dom.slider.querySelector(".slide.-active .img.--index1 img")],

        {
          scale: 1.2,
          ease: "power3.inOut",
          duration: 2.5,
          delay: 2,
        },
        {
          scale: 1,
        }
      )
      .fromTo(
        [this.dom.slider.querySelector(".slide.-active .img.--index2 img")],

        {
          scale: 1.2,
          ease: "power3.inOut",
          duration: 2.5,
          delay: 3,
        },
        {
          scale: 1,
        }
      )
      .fromTo(
        [this.dom.slider.querySelector(".js-slider__caption h4")],
        {
          opacity: 0,
          y: 90,
          rotation: 7,
          transformOrigin: "0% 0%",
          duration: 2.5,
          delay: 0.9,
          ease: "power3.inOut",
        },
        {
          opacity: 1,
          y: 0,
          rotation: 0,
        },
        1.8
      )
      .staggerFromTo(
        [
          ...this.dom.slider.querySelectorAll(
            ".js-slider__caption h2 .line span"
          ),
        ],
        1.5,
        {
          opacity: 0,
          y: 90,
          rotation: 12,
          transformOrigin: "0% 0%",
        },
        {
          opacity: 1,
          y: 0,
          rotation: 0,
          ease: "power3.inOut",
        },
        0.15,
        2.1
      );
  }

  addEvents() {
    // this.dom.elm.addEventListener("click", this.next);

    this.dom.indicators.forEach((indicator, index) => {
      indicator.addEventListener("click", (e) => {
        this.go(index);
      });
    });
  }

  init() {
    this.addEvents();
    // this.setInitAnimation();
    if (this.data.indicators) {
      this.setTimer();
    }
  }
}
