import { debounce, find, uniqBy, filter, orderBy } from "lodash";
import bootstrap from "bootstrap";
import Tooltip from "bootstrap/js/dist/tooltip";
import Popover from "bootstrap/js/dist/popover";
import { gsap } from "gsap";
import { tns } from "tiny-slider/src/tiny-slider";
import Rellax from "rellax";
import Zooming from "zooming";
import sal from "sal.js";
import GLightbox from "glightbox";
import Splitting from "splitting";
import objectFitImages from "object-fit-images";
import SmoothScroll from "smooth-scroll";
import { appHeroSlider } from "./appHeroSlider";
import { appShop } from "./appShop";

(function () {
  let servicePhlebology = () => {
    const elm = document.querySelector(".js-service-slider-phlebology");
    if (!elm) {
      return false;
    }
    const slider = tns({
      container: elm,
      items: 1,
      responsive: {
        576: {
          items: 1,
        },
        768: {
          items: 1,
        },
        992: {
          items: 2,
        },
        1200: {
          items: 3,
        },
        1400: {
          items: 4,
        },
      },
      autoplayButtonOutput: false,
      navPosition: "bottom",
      mouseDrag: true,
      //autoplayTimeout: 5000,
      gutter: 1,
      nav: true,
      controls: true,
      //slideBy: "page",
      //autoplay: true,
    });
  };

  let serviceAesthetic = () => {
    const elm = document.querySelector(".js-service-slider-aesthetic");
    if (!elm) {
      return false;
    }
    const slider = tns({
      container: elm,
      items: 1,
      responsive: {
        576: {
          items: 1,
        },
        768: {
          items: 1,
        },
        992: {
          items: 2,
        },
        1200: {
          items: 3,
        },
        1400: {
          items: 4,
        },
      },
      autoplayButtonOutput: false,
      navPosition: "bottom",
      //mouseDrag: true,
      autoplayTimeout: 5000,
      gutter: 1,
      nav: false,
      controls: true,
      //slideBy: "page",
      autoplay: true,
    });
  };

  let metamorphosis = () => {
    const elm = document.querySelector(".js-metamorphosis-slider");
    if (!elm) {
      return false;
    }
    const slider = tns({
      container: elm,
      items: 1,
      autoplayButtonOutput: false,
      navPosition: "bottom",
      //mouseDrag: true,
      autoplayTimeout: 6000,
      gutter: 1,
      nav: true,
      controls: true,
      //slideBy: "page",
      autoplay: true,
    });
  };

  let team = () => {
    const elm = document.querySelector(".js-team-slider");
    if (!elm) {
      return false;
    }
    const slider = tns({
      container: elm,
      items: 1,
      responsive: {
        576: {
          items: 1,
        },
        768: {
          items: 2,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 4,
        },
        1400: {
          items: 5,
        },
      },
      autoplayButtonOutput: false,
      navPosition: "bottom",
      //mouseDrag: true,
      autoplayTimeout: 5000,
      autoplay: true,
      gutter: 30,
      nav: true,
      controls: true,
      //center: true,
      //slideBy: "page",
      swipeAngle: false,
      mouseDrag: true,
    });
  };

  let blogRelated = () => {
    const elm = document.querySelector(".js-blog-related-slider");
    if (!elm) {
      return false;
    }
    const slider = tns({
      container: elm,
      items: 1,
      responsive: {
        576: {
          items: 1,
        },
        768: {
          items: 2,
        },
        992: {
          items: 2,
        },
        1200: {
          items: 3,
        },
        1400: {
          items: 3,
        },
      },
      autoplayButtonOutput: false,
      navPosition: "bottom",
      //mouseDrag: true,
      autoplayTimeout: 5000,
      autoplay: true,
      gutter: 30,
      nav: true,
      controls: true,
      //center: true,
      //slideBy: "page",
      //autoplay: true,
    });
  };

  let areaGallery = () => {
    const elm = document.getElementById("area-gallery");
    if (!elm) {
      return false;
    }

    let itemSet = {
      xs: 1,
      md: 1,
      lg: 2,
      xl: 3,
      xxl: 4,
    };
    if (elm.dataset.cnt) {
      if (elm.dataset.cnt == 1) {
        itemSet = {
          xs: 1,
          md: 1,
          lg: 1,
          xl: 1,
          xxl: 1,
        };
      } else if (elm.dataset.cnt == 2) {
        itemSet = {
          xs: 1,
          md: 1,
          lg: 1,
          xl: 2,
          xxl: 2,
        };
      } else if (elm.dataset.cnt == 3) {
        itemSet = {
          xs: 1,
          md: 1,
          lg: 2,
          xl: 3,
          xxl: 3,
        };
      }
    }
    const areaGallerySlider = tns({
      container: "#area-gallery",
      items: 1,
      responsive: {
        576: {
          items: itemSet.xs,
          mouseDrag: true,
        },
        768: {
          items: itemSet.md,
        },
        992: {
          items: itemSet.lg,
        },
        1200: {
          items: itemSet.xl,
        },
        1400: {
          items: itemSet.xxl,
        },
      },
      // draggable: true,
      // dragAutoSnap: true,
      swipeAngle: false,
      mouseDrag: true,
      autoplayButtonOutput: false,
      navPosition: "bottom",
      autoplayTimeout: 4000,
      gutter: elm.dataset.gutter ?? 30,
      nav: elm.dataset.nav ?? false,
      controls: true,
      // slideBy: "page",
      loop: true,
      autoplay: elm.dataset.autoplay ?? true,
      // mouseDrag: true,
    });
  };

  let navFixed = () => {
    let isFixed = false;
    const fixedOffset = 60;
    const navElm = document.querySelector(".js-navbar");
    const bodyElm = document.querySelector("body");

    if (!navElm) {
      return false;
    }

    const toggleFixed = () => {
      if (
        (!isFixed && window.pageYOffset >= fixedOffset) ||
        (isFixed && window.pageYOffset < fixedOffset)
      ) {
        if (isFixed) {
          navElm.classList.remove("-fixed");
          bodyElm.classList.remove("-nav-fixed");
        } else {
          navElm.classList.add("-fixed");
          bodyElm.classList.add("-nav-fixed");
        }
        isFixed = !isFixed;
      }
    };

    toggleFixed();

    window.addEventListener(
      "scroll",
      _.throttle(() => {
        toggleFixed();
      }, 100)
    );

    window.addEventListener("resize", (e) => { });
  };

  let initObjectFitImages = () => {
    objectFitImages();
  };

  let initSplitting = () => {
    document.querySelectorAll("[data-split-letter]").forEach((elm) => {
      Splitting({
        target: elm,
        //whitespace: true,
        by: "chars",
      });
    });
    document.querySelectorAll("[data-split-word]").forEach((elm) => {
      Splitting({
        target: elm,
        //whitespace: true,
        by: "words",
      });
    });
    document.querySelectorAll("[data-split-line]").forEach((elm) => {
      Splitting({
        target: elm,
        //whitespace: true,
        by: "lines",
      });
    });
  };

  let initSmoothScroll = () => {
    new SmoothScroll("[data-to]", {
      speed: 500,
      speedAsDuration: true,
      easing: "easeInOutCubic",
      offset: function (anchor, toggle) {
        return toggle.dataset.offset ? toggle.dataset.offset : 110;
      },
      // header: '[data-scroll-header]' //offset with sticky nav
    });
  };

  const initRellaxScroll = () => {
    const rellax = new Rellax("[data-rellax]", {
      breakpoints: [576, 768, 1201],
      center: true,
      //horizontal: true,
    });
  };

  const initSal = () => {
    sal({
      threshold: 0.15,
      once: true,
      //rootMargin: `0% 20%`
    });
  };

  let initZooming = () => {
    new Zooming({
      customSize: `100%`,
      bgOpacity: 0,
      scaleExtra: 0.25,
      onBeforeOpen: (img) => {
        img.parentNode.classList.add("--zoomed");
      },
      onBeforeClose: (img) => {
        img.parentNode.classList.remove("--zoomed");
      },
    }).listen("[data-zoom-image]");
  };

  let initGLightbox = () => {
    const lightbox = GLightbox({
      selector: "[data-glightbox],.glightbox",
      touchNavigation: true,
      loop: true,
    });
  };

  let initBootstrap = () => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });

    var popoverTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    );
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new Popover(popoverTriggerEl);
    });
  };

  let handleForm = () => {
    const formElm = document.querySelector("[data-app-form]");
    const formBtnSubmitElm = document.querySelector(
      "[data-app-form-btn-submit]"
    );
    const formMsgElm = document.querySelector("[data-app-form-msg]");
    const bodyElm = document.querySelector("body");

    if (!formElm) return;

    formElm.addEventListener("submit", (e) => {
      e.preventDefault();
      const btnSubmitlabel = formBtnSubmitElm.innerHTML;
      formBtnSubmitElm.innerHTML = `<div class="spinner-border spinner-border-sm text-light" role="status"></div>`;

      const formData = new FormData(formElm);

      fetch(formElm.action, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.type && data.msg) {
            formMsgElm.querySelector(".msg__txt").innerHTML = data.msg;
            formMsgElm.classList.add(`--${data.type}`);
            gsap.set(formMsgElm, {
              height: "auto",
              opacity: 1,
            });
            gsap.from(formMsgElm, {
              duration: 0.75,
              height: 0,
              opacity: 0,
              ease: "power4.inOut",
            });
            gsap.to(formElm, {
              duration: 0.75,
              height: 0,
              opacity: 0,
              ease: "power4.inOut",
            });
          } else {
            formBtnSubmitElm.innerHTML = btnSubmitlabel;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  // sliders
  servicePhlebology();
  serviceAesthetic();
  metamorphosis();
  team();
  blogRelated();

  //areas
  areaGallery();

  // nav
  navFixed();

  // form
  handleForm();

  // misc
  initObjectFitImages();
  initSplitting();
  initSmoothScroll();

  // scroll effect
  initRellaxScroll();
  initSal();

  // gallery
  initZooming();
  initGLightbox();

  initBootstrap();

  const heroSlider = new appHeroSlider();
  const shop = new appShop();

})();
