import { each, debounce, find, uniqBy, filter, orderBy, keysIn, keyBy } from "lodash";

export class appShop {
  constructor() {
    this.elm = document.querySelector("[data-app-shop]");

    if (!this.elm) return;

    this.dom = {
      elm: this.elm,
      productList: this.elm.querySelectorAll("[data-app-shop-product]"),
      formProduct: this.elm.querySelector("[data-app-form-product]"),
      formTotal: this.elm.querySelector("[data-app-form-total]"),
      formSelectedProduct: this.elm.querySelector("[data-app-form-selected-product]"),
      formPrice: this.elm.querySelector("[data-app-form-price]"),
      form: this.elm.querySelector("[data-app-form]"),
      formMsg: this.elm.querySelector("[data-app-form-msg]"),
    };

    this.data = {
      productList: {},
      productAll: '',
      isEmpty: true,
      price: 0
    };

    this.init();
  }

  isTouchDevice() {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }

  addEvents() {
    this.dom.productList.forEach(elm => {
      elm.querySelector("[data-app-shop-order]").addEventListener("click", (e) => {
        e.preventDefault();

        let isComplete = true;
        let detailsName = "";
        let detailsId = "";
        ['size', 'color', 'attribute'].forEach(key => {
          let detailsElm = elm.querySelector("[data-app-shop-" + key + "]");

          if (detailsElm != null) {
            if (!detailsElm.value) {
              isComplete = false;

              // alert('Wybierz szczegóły produktu');
              //console.log(elm.querySelector("[data-app-shop-" + key + "]").value);
            } else {
              detailsName += detailsElm.value + ",";
              elm.dataset.details = detailsName.slice(0, -1);
              detailsId += "-" + detailsElm.selectedIndex;
              elm.dataset.detailsId = detailsId;
              console.log(detailsName, detailsId);
            }
          }
        });

        if (!isComplete) {
          alert('Wybierz szczegóły produktu');
        } else {

          if (this.data.productList[elm.dataset.id + elm.dataset.detailsId]) {
            this.data.productList[elm.dataset.id + elm.dataset.detailsId].quantity++;
          } else {
            this.data.productList[elm.dataset.id + elm.dataset.detailsId] = {
              name: elm.dataset.name + ((elm.dataset.details) ? " - " + elm.dataset.details : ""),
              price: parseInt(elm.dataset.price),
              quantity: 1
            };
          }

          window.scrollTo(0, this.dom.formMsg.getBoundingClientRect().top + window.scrollY - 110);

          this.updatePrice();
          this.updateProductList();
        }
      });
    });

  }

  updatePrice() {
    this.data.price = 0;
    _.each(this.data.productList, (item, key) => {
      this.data.price = parseInt(item.price * item.quantity) + parseInt(this.data.price);
    });
    this.dom.formPrice.innerHTML = `${this.data.price} zł`;
    this.dom.formTotal.value = `${this.data.price} zł`;
    if (this.data.price > 0) {
      this.dom.formPrice.classList.remove('d-none');
    } else {
      this.dom.formPrice.classList.add('d-none');
    }
  }

  updateProductList() {
    let selectedProduct = "";
    let selectedProductHtml = '';
    _.each(this.data.productList, (item, key) => {
      selectedProductHtml += `
        <div class="product" data-id="${key}">
          <span>${item.quantity}x ${item.name} / ${item.price} zł</span> 
          <button type="button" class="btn-close"></button>
        </div>
      `;
      selectedProduct += `${item.quantity}x ${item.name} / ${item.price} zł;`;
    });

    this.dom.formSelectedProduct.innerHTML = selectedProductHtml;

    // this.data.productAll = elm.dataset.name + ((elm.dataset.details) ? " - " + elm.dataset.details : "") + "; " + this.data.productAll;
    this.dom.formProduct.value = selectedProduct;

    this.dom.formSelectedProduct.querySelectorAll('.product').forEach((elm, key) => {
      elm.querySelector('button').addEventListener('click', (e) => {
        elm.remove();
        delete this.data.productList[elm.dataset.id];
        this.updatePrice();
      });
    });
  }

  init() {
    this.addEvents();
    console.log(this.dom)
  }
}
